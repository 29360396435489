export function actualDownloadCsv(csvOrCsvRows, filename) {
  const csv = typeof csvOrCsvRows === 'string' ? csvOrCsvRows : csvOrCsvRows.map(rowToCsv).join('\n');
  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;'
  });

  // download from blob
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
function rowToCsv(row) {
  return row.map(quoteCsvFields).join(',');
}
function quoteCsvFields(text) {
  return "\"".concat(('' + text).replace(/"/g, '""'), "\"");
}