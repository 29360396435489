// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eqfJX{display:flex;flex-direction:row;align-items:center;gap:5px}.THPq5{display:flex;align-items:center}.DpqiJ{max-width:220px;cursor:pointer}.LFGHk{cursor:pointer}.oh3C_{width:100%;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeActivitiesHeader": `eqfJX`,
	"timeActivitiesServicesSelector": `THPq5`,
	"timeActivitiesServiceName": `DpqiJ`,
	"arrowIcon": `LFGHk`,
	"timeActivitiesEmptyState": `oh3C_`
};
export default ___CSS_LOADER_EXPORT___;
