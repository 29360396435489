import { compact } from 'lodash';
import { getNameValidator, getEmailValidator, getCompanyNameValidator, titleValidation, composeValidators, maxLengthValidation, noHtmlPatternValidation, requiredValidation, getTitleValidator } from 'validators';
export const validateContact = {
  firstName: getNameValidator('First name', 'Please enter a first name'),
  lastName: getNameValidator('Last name', 'Please enter a last name'),
  email: getEmailValidator(undefined, 'Please enter an email address'),
  companyName: getCompanyNameValidator(undefined, 'Please enter a company name')
};
export const validateAgreementRecipient = {
  firstName: getNameValidator('First name'),
  lastName: getNameValidator('Last name'),
  email: getEmailValidator()
};
export const agreementNameValidator = value => getTitleValidator('Agreement name', 'Please provide an agreement name.')(value);

/** For react-hook-form library. */
export const agreementNameValidation = {
  validate: {
    title: agreementNameValidator
  },
  dataAttr: {
    'data-validation': 'title'
  }
};
export const templateNameValidation = titleValidation('Template name', {
  required: 'Please provide a template name'
});
export function getCustomMessageValidation(label, required) {
  return composeValidators(compact([required && requiredValidation('Required'), maxLengthValidation(4000, label), noHtmlPatternValidation(label)]));
}