import { userSelectors } from "./userSelectors";
import { ff } from "./ffSelectors";
import { filterAvailableRoutes } from "./filterAvailableRoutes";
import { integrationSelectors } from "./integrationSelectors";
import { KYBProgressStatusEnum, KYBProgressStatusHelpers } from 'anchor-kyb-service/types';
function impersonatingAs(state) {
  return state.claims ? state.claims.impersonate : null;
}
export const selectors = {
  ff,
  user: userSelectors,
  integration: integrationSelectors,
  filterAvailableRoutes,
  impersonatingAs,
  intercomUserHash(state) {
    return state.claims ? state.claims.intercomUserHash : null;
  },
  isResetPasswordFlow(_ref) {
    let {
      claims
    } = _ref;
    return claims ? claims.setPasswordOnly : false;
  },
  mgmtHost(state) {
    return state.session.mgmtHost;
  },
  pdfHost(state) {
    return state.session.pdfHost;
  },
  hasSentAProposal(state) {
    var _state$businessInfo;
    return ((_state$businessInfo = state.businessInfo) === null || _state$businessInfo === void 0 ? void 0 : _state$businessInfo.hasSentAProposal) || false;
  },
  showMissingColumnBankAccountBanner(s) {
    var _s$personaKYBStatus$d;
    const userInfo = s.userInfo;
    return !!userInfo && userInfo.isVendor() && (userInfo.isOwner() || userInfo.isAdmin()) && !userInfo.paymentsDisabled() && userInfo.columnEntityCreated() && userInfo.hasColumnAccountId() && userInfo.hasColumnWalletAccountId() && !userInfo.hasColumnPayoutAccountId() && ((_s$personaKYBStatus$d = s.personaKYBStatus.data) === null || _s$personaKYBStatus$d === void 0 ? void 0 : _s$personaKYBStatus$d.status) === KYBProgressStatusEnum.Approved;
  },
  showUpdateKYBBanner(s) {
    const userInfo = s.userInfo;
    const {
      status
    } = s.personaKYBStatus.data || {};
    return !!status && !!userInfo && !KYBProgressStatusHelpers.isApproved(status) && !KYBProgressStatusHelpers.isAwaitingDecision(status) && userInfo.isVendor() && (userInfo.isOwner() || userInfo.isAdmin()) && !userInfo.paymentsDisabled();
  },
  shouldLoadAppcues(s) {
    return userSelectors.isVendor(s) && s.session.enableAppcues;
  },
  isRealUser(s) {
    return !!(s.userInfo && s.userInfo.id() && !impersonatingAs(s));
  }
};