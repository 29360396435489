// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qnxcu{display:flex;flex-direction:column;gap:16px;padding:12px 16px 16px 24px}.Vbs6s{display:flex;align-items:center;gap:4px}.Vbs6s>:first-child{flex-shrink:0}.JaAmA{display:flex;align-items:center;gap:4px}.HZVdE{min-width:60px}.MgFck{display:flex;justify-content:space-between}.bsi4H{margin-bottom:16px}.Hj9uF{display:flex;flex-direction:column;gap:16px;flex:1}.fhVYe{display:flex;flex-direction:column;gap:16px}.st7Gv{display:flex;flex-direction:row;justify-content:space-between;align-items:baseline}.FYn6R{cursor:pointer}.EsLXT{width:100%;position:relative}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noteAndCalloutsContainer": `Qnxcu`,
	"rateWithUnit": `Vbs6s`,
	"rate": `JaAmA`,
	"units": `HZVdE`,
	"discount": `MgFck`,
	"creditCallout": `bsi4H`,
	"modalContentWrapper": `Hj9uF`,
	"callouts": `fhVYe`,
	"billModalDatesWrapper": `st7Gv`,
	"showTimeActivitiesText": `FYn6R`,
	"ensureNoHorizontalScrollFromDrawer": `EsLXT`
};
export default ___CSS_LOADER_EXPORT___;
