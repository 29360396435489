import { compact } from 'lodash';
import { ActiveServicesReportEntryApiAutomaticServiceState, ActiveServicesReportEntryApiOccurrence, ActiveServicesReportEntryApiPricing, ActiveServicesReportEntryApiStatus, ActiveServicesReportEntryApiTrigger, ActiveServicesReportSortColumnId } from 'anchor-scout/types';
import { SearchFilterType } from "../components/types";
import { ActiveServicesReportOptionalColumns, FilterType } from "./types";
export const makeCsv = (headerRow, visibleColumns, entries) => [headerRow].concat(entries.map(entry => {
  const row = [entry.customerName, entry.agreementName, entry.serviceName];
  if (visibleColumns[ActiveServicesReportOptionalColumns.Trigger]) {
    row.push(entry.trigger);
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.Pricing]) {
    row.push(entry.pricing);
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.Occurrence]) {
    row.push(entry.occurrence);
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.Rate]) {
    row.push(entry.rate);
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.LastBilled]) {
    row.push(entry.formattedLastBilled || '');
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.Assignee]) {
    row.push(entry.assignee);
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.AutomaticServiceState]) {
    row.push(entry.automaticServiceState || '');
  }
  if (visibleColumns[ActiveServicesReportOptionalColumns.Status]) {
    row.push(entry.status);
  }
  return row;
}));
export const activeServicesReportHeaderColumnDefsForExport = visibleColumns => compact([{
  value: 'Company name'
}, {
  value: 'Agreement name'
}, {
  value: 'Service name'
}, visibleColumns[ActiveServicesReportOptionalColumns.Trigger] && {
  value: 'Trigger'
}, visibleColumns[ActiveServicesReportOptionalColumns.Pricing] && {
  value: 'Pricing'
}, visibleColumns[ActiveServicesReportOptionalColumns.Occurrence] && {
  value: 'Occurrence'
}, visibleColumns[ActiveServicesReportOptionalColumns.Rate] && {
  value: 'Rate'
}, visibleColumns[ActiveServicesReportOptionalColumns.LastBilled] && {
  value: 'Last billed'
}, visibleColumns[ActiveServicesReportOptionalColumns.Assignee] && {
  value: 'Assignee'
}, visibleColumns[ActiveServicesReportOptionalColumns.AutomaticServiceState] && {
  value: 'Automatic service state'
}, visibleColumns[ActiveServicesReportOptionalColumns.Status] && {
  value: 'Status'
}]);
export const activeServicesReportHeaderColumnDefs = visibleColumns => compact([{
  sortId: ActiveServicesReportSortColumnId.CompanyName,
  value: 'Company name'
}, {
  sortId: ActiveServicesReportSortColumnId.ServiceName,
  value: 'Service name'
}, visibleColumns[ActiveServicesReportOptionalColumns.Trigger] && {
  sortId: ActiveServicesReportSortColumnId.Trigger,
  value: 'Trigger',
  optionalColumn: ActiveServicesReportOptionalColumns.Trigger
}, visibleColumns[ActiveServicesReportOptionalColumns.Pricing] && {
  sortId: ActiveServicesReportSortColumnId.Pricing,
  value: 'Pricing',
  optionalColumn: ActiveServicesReportOptionalColumns.Pricing
}, visibleColumns[ActiveServicesReportOptionalColumns.Occurrence] && {
  sortId: ActiveServicesReportSortColumnId.Occurrence,
  value: 'Occurrence',
  optionalColumn: ActiveServicesReportOptionalColumns.Occurrence
}, visibleColumns[ActiveServicesReportOptionalColumns.Rate] && {
  sortId: ActiveServicesReportSortColumnId.Rate,
  value: 'Rate',
  optionalColumn: ActiveServicesReportOptionalColumns.Rate
}, visibleColumns[ActiveServicesReportOptionalColumns.LastBilled] && {
  sortId: ActiveServicesReportSortColumnId.LastBilled,
  value: 'Last billed',
  optionalColumn: ActiveServicesReportOptionalColumns.LastBilled
}, visibleColumns[ActiveServicesReportOptionalColumns.Assignee] && {
  sortId: ActiveServicesReportSortColumnId.Assignee,
  value: 'Assignee',
  optionalColumn: ActiveServicesReportOptionalColumns.Assignee
}, visibleColumns[ActiveServicesReportOptionalColumns.AutomaticServiceState] && {
  sortId: ActiveServicesReportSortColumnId.AutomaticServiceState,
  value: 'Automatic service state',
  optionalColumn: ActiveServicesReportOptionalColumns.AutomaticServiceState
}, visibleColumns[ActiveServicesReportOptionalColumns.Status] && {
  sortId: ActiveServicesReportSortColumnId.Status,
  value: 'Status',
  optionalColumn: ActiveServicesReportOptionalColumns.Status
}]);
export const activeServicesReportColumnDefs = visibleColumns => compact([{
  width: 234,
  sortable: true
}, {
  width: 234,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.Trigger] && {
  width: 108,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.Pricing] && {
  width: 108,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.Occurrence] && {
  width: 108,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.Rate] && {
  width: 148,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.LastBilled] && {
  width: 148,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.Assignee] && {
  width: 180,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.AutomaticServiceState] && {
  width: 180,
  sortable: true
}, visibleColumns[ActiveServicesReportOptionalColumns.Status] && {
  width: 108,
  sortable: true
}]);
export const filterLabels = {
  [FilterType.CustomerName]: 'Company name',
  [FilterType.AgreementName]: 'Agreement name',
  [FilterType.ServiceName]: 'Service name',
  [FilterType.Occurrence]: 'Occurrence',
  [FilterType.Trigger]: 'Trigger',
  [FilterType.Pricing]: 'Pricing',
  [FilterType.LastBilled]: 'Last billed',
  [FilterType.Assignee]: 'Assignee',
  [FilterType.AutomaticServiceState]: 'Automatic service state',
  [FilterType.Status]: 'Status'
};
const occurrenceFilterOptions = [{
  label: 'One-time',
  value: ActiveServicesReportEntryApiOccurrence.OneTime
}, {
  label: 'Weekly',
  value: ActiveServicesReportEntryApiOccurrence.Weekly
}, {
  label: 'Bi-weekly',
  value: ActiveServicesReportEntryApiOccurrence.BiWeekly
}, {
  label: 'Monthly',
  value: ActiveServicesReportEntryApiOccurrence.Monthly
}, {
  label: 'Quarterly',
  value: ActiveServicesReportEntryApiOccurrence.Quarterly
}, {
  label: 'Yearly',
  value: ActiveServicesReportEntryApiOccurrence.Yearly
}];
const triggerFilterOptions = [{
  label: 'Manual',
  value: ActiveServicesReportEntryApiTrigger.Manual
}, {
  label: 'Automatic',
  value: ActiveServicesReportEntryApiTrigger.Automatic
}];
const pricingFilterOptions = [{
  label: 'Fixed',
  value: ActiveServicesReportEntryApiPricing.Fixed
}, {
  label: 'Per hour',
  value: ActiveServicesReportEntryApiPricing.PerHour
}, {
  label: 'Per unit',
  value: ActiveServicesReportEntryApiPricing.PerUnit
}, {
  label: 'Range',
  value: ActiveServicesReportEntryApiPricing.Range
}];
const pausedFilterOptions = [{
  label: 'Paused',
  value: ActiveServicesReportEntryApiAutomaticServiceState.Paused
}, {
  label: 'Active',
  value: ActiveServicesReportEntryApiAutomaticServiceState.Active
}];
const statusFilterOptions = [{
  label: 'Approved',
  value: ActiveServicesReportEntryApiStatus.Approved
}, {
  label: 'Amended',
  value: ActiveServicesReportEntryApiStatus.Amended
}];
export const makeFiltersConfig = (serviceNames, customerNames, agreementNames, assignees) => ({
  [FilterType.CustomerName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: customerNames,
    removable: true,
    withSearchbox: true
  },
  [FilterType.ServiceName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: serviceNames,
    removable: true,
    withSearchbox: true
  },
  [FilterType.AgreementName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: agreementNames,
    removable: true,
    withSearchbox: true
  },
  [FilterType.Assignee]: {
    type: SearchFilterType.MultiSelectFilter,
    options: assignees,
    removable: true,
    withSearchbox: true
  },
  [FilterType.Occurrence]: {
    type: SearchFilterType.MultiSelectFilter,
    options: occurrenceFilterOptions,
    removable: true,
    withSearchbox: false
  },
  [FilterType.Trigger]: {
    type: SearchFilterType.MultiSelectFilter,
    options: triggerFilterOptions,
    removable: true,
    withSearchbox: false
  },
  [FilterType.Pricing]: {
    type: SearchFilterType.MultiSelectFilter,
    options: pricingFilterOptions,
    removable: true,
    withSearchbox: false
  },
  [FilterType.AutomaticServiceState]: {
    type: SearchFilterType.MultiSelectFilter,
    options: pausedFilterOptions,
    removable: true,
    withSearchbox: false
  },
  [FilterType.Status]: {
    type: SearchFilterType.MultiSelectFilter,
    options: statusFilterOptions,
    removable: true,
    withSearchbox: false
  },
  [FilterType.LastBilled]: {
    type: SearchFilterType.DateRangeFilter,
    removable: true
  }
});
export const getDefaultSort = sortIds => ({
  colIndex: sortIds.indexOf(ActiveServicesReportSortColumnId.CompanyName),
  sort: 'desc'
});