import { compact } from 'lodash';
import { EditDraftsTableColumns } from "./types";
export const editDraftsTableHeaderColumnDefs = visibleColumns => compact([{
  value: EditDraftsTableColumns.Client,
  width: 220,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.AdditionalRecipients] && {
  value: EditDraftsTableColumns.AdditionalRecipients,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.AgreementName] && {
  value: EditDraftsTableColumns.AgreementName,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.EffectiveDate] && {
  value: EditDraftsTableColumns.EffectiveDate,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.ModifiedBy] && {
  value: EditDraftsTableColumns.ModifiedBy,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.LastModified] && {
  value: EditDraftsTableColumns.LastModified,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.ExpirationDate] && {
  value: EditDraftsTableColumns.ExpirationDate,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.IntroductoryMessage] && {
  value: EditDraftsTableColumns.IntroductoryMessage,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.IntroductoryVideo] && {
  value: EditDraftsTableColumns.IntroductoryVideo,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.TermsOfService] && {
  value: EditDraftsTableColumns.TermsOfService,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.AgreementNote] && {
  value: EditDraftsTableColumns.AgreementNote,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.AutoApproveAmendments] && {
  value: EditDraftsTableColumns.AutoApproveAmendments,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.AutoApproveOutOfScopeCharges] && {
  value: EditDraftsTableColumns.AutoApproveOutOfScopeCharges,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.NumberOfDaysUntilPaymentIsDue] && {
  value: EditDraftsTableColumns.NumberOfDaysUntilPaymentIsDue,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.PaymentMethodRequiredForSigning] && {
  value: EditDraftsTableColumns.PaymentMethodRequiredForSigning,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.CreditCardProcessingFees] && {
  value: EditDraftsTableColumns.CreditCardProcessingFees,
  width: 200,
  sortable: true
}, visibleColumns[EditDraftsTableColumns.ManualApprovalOfEachPayment] && {
  value: EditDraftsTableColumns.ManualApprovalOfEachPayment,
  width: 200,
  sortable: true
}]);
export const getUICompanyName = (draft, contact) => {
  return draft.isSample ? 'Anchor (Sample)' : contact ? contact.companyName() : '';
};