import { UserRole } from "../../../../../models/UserInfoModel";
import { BusinessPartyTypeEnum } from "../../../../../store/store.model";
export function isValidWorkspace(workspace) {
  if (workspace.role === UserRole.Accountant) {
    // You can switch to a workspace where you are an accountant only from the contacts dashboard.
    return false;
  }
  return workspace.features.accountsPayable || workspace.features.accountsReceivable && workspace.setupCompleted;
}
export function getBusinessSwitcherItemSubtext(workspace) {
  switch (workspace.type) {
    case BusinessPartyTypeEnum.Vendor:
      return 'Service Provider account';
    case BusinessPartyTypeEnum.Customer:
      return "Client of ".concat(workspace.customer.vendor.name);
    case BusinessPartyTypeEnum.MultiVendorCustomer:
      return 'Client account';
  }
}
export function getBusinessSwitcherItemLogoUrl(workspace) {
  switch (workspace.type) {
    case BusinessPartyTypeEnum.Vendor:
      return workspace.logoUrl || '';
    case BusinessPartyTypeEnum.Customer:
      return workspace.customer.vendor.logoUrl || '';
    case BusinessPartyTypeEnum.MultiVendorCustomer:
      return '';
  }
}
export function getBusinessTriggerSubtext(worksAt) {
  if (!worksAt) {
    return '';
  }
  switch (worksAt.type) {
    case BusinessPartyTypeEnum.Vendor:
      return '';
    case BusinessPartyTypeEnum.Customer:
      return "Client of ".concat(worksAt.customer.vendor.name);
    case BusinessPartyTypeEnum.MultiVendorCustomer:
      return '';
  }
}
export function getBusinessTriggerLogoUrl(worksAt) {
  if (!worksAt) {
    return '';
  }
  switch (worksAt.type) {
    case BusinessPartyTypeEnum.Vendor:
      return worksAt.logoUrl || '';
    case BusinessPartyTypeEnum.Customer:
      return worksAt.customer.vendor.logoUrl || '';
    case BusinessPartyTypeEnum.MultiVendorCustomer:
      return '';
  }
}