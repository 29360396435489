import { RelationshipStatus } from "./model";
import * as serviceModel from "../service";
import { IntroVideoType } from "../intro-video";
import { fullName } from "../../infra/utils";
import pluralize from 'pluralize';
import { timeModel } from '..';
import { relationshipDisplayedTitle } from "../../business-logic/relationshipLogic";
export function id(relationship) {
  return relationship.id;
}
export function isSample(relationship) {
  return relationship.isSample;
}
export function isVendorCoveringCCFee(relationship) {
  return relationship.feeControls.coverCreditCardFees;
}
export function updatedIsVendorCoveringCCFee(relationship) {
  return relationship.pendingAmendments.find(a => a.isCoverCreditCardFees());
}
export function updatedIsVendorCoveringCCFeeValue(relationship) {
  var _updatedIsVendorCover;
  return (_updatedIsVendorCover = updatedIsVendorCoveringCCFee(relationship)) === null || _updatedIsVendorCover === void 0 ? void 0 : _updatedIsVendorCover.value();
}
export function updatedIsVendorCoveringCCFeeAutoApprovalDate(relationship) {
  const updated = updatedIsVendorCoveringCCFee(relationship);
  return updated ? updated.approvalMethod().autoApprovalDate() : null;
}
export function actualIsVendorCoveringCCFee(relationship) {
  var _updatedIsVendorCover2;
  return (_updatedIsVendorCover2 = updatedIsVendorCoveringCCFeeValue(relationship)) !== null && _updatedIsVendorCover2 !== void 0 ? _updatedIsVendorCover2 : isVendorCoveringCCFee(relationship);
}
export function vendor(relationship) {
  return relationship.vendor.id;
}
export function customer(relationship) {
  return relationship.customer.id;
}
export function getService(relationship, serviceId) {
  return relationship.services.find(service => service.id === serviceId);
}
export function effectiveDate(relationship) {
  const effectiveDate = relationship.effectiveDate;
  return effectiveDate != null ? new Date("".concat(effectiveDate.year, "-").concat(effectiveDate.month, "-").concat(effectiveDate.day)) : null;
}
export function updatedNetTerms(relationship) {
  return relationship.pendingAmendments.find(a => a.isNetTerms());
}
export function updatedNetTermsAutoApprovalDate(relationship) {
  const updated = updatedNetTerms(relationship);
  return updated ? updated.approvalMethod().autoApprovalDate() : null;
}
export function updatedNetTermsValue(relationship) {
  var _updatedNetTerms;
  return (_updatedNetTerms = updatedNetTerms(relationship)) === null || _updatedNetTerms === void 0 ? void 0 : _updatedNetTerms.value();
}

/** The actual netTerms are the current netTerms, or the netTerms that are awaiting client approval following an amendment. */
export function actualNetTerms(relationship) {
  const updatedNetTerms = updatedNetTermsValue(relationship);
  return updatedNetTerms != null ? updatedNetTerms : relationship.netTerms;
}
export function updatedAmendmentsApprovalMethod(relationship) {
  return relationship.pendingAmendments.find(a => a.isAmendmentsApprovalMethod());
}
export function updatedInvoicesApprovalMethod(relationship) {
  return relationship.pendingAmendments.find(a => a.isInvoicesApprovalMethod());
}
export function updatedAmendmentsApprovalMethodValue(relationship) {
  var _updatedAmendmentsApp;
  return (_updatedAmendmentsApp = updatedAmendmentsApprovalMethod(relationship)) === null || _updatedAmendmentsApp === void 0 ? void 0 : _updatedAmendmentsApp.value();
}
export function updatedInvoicesApprovalMethodValue(relationship) {
  var _updatedInvoicesAppro;
  return (_updatedInvoicesAppro = updatedInvoicesApprovalMethod(relationship)) === null || _updatedInvoicesAppro === void 0 ? void 0 : _updatedInvoicesAppro.value();
}
export function updatedAmendmentsApprovalMethodAutoApprovalDate(relationship) {
  const updated = updatedAmendmentsApprovalMethod(relationship);
  return updated ? updated.approvalMethod().autoApprovalDate() : null;
}
export function updatedInvoicesApprovalMethodAutoApprovalDate(relationship) {
  const updated = updatedInvoicesApprovalMethod(relationship);
  return updated ? updated.approvalMethod().autoApprovalDate() : null;
}
export function actualAmendmentsApprovalMethod(relationship) {
  const updatedAmendmentsApprovalMethod = updatedAmendmentsApprovalMethodValue(relationship);
  return updatedAmendmentsApprovalMethod != null ? updatedAmendmentsApprovalMethod : relationship.amendmentsApprovalMethod;
}
export function actualInvoicesApprovalMethod(relationship) {
  const updatedInvoicesApprovalMethod = updatedInvoicesApprovalMethodValue(relationship);
  return updatedInvoicesApprovalMethod != null ? updatedInvoicesApprovalMethod : relationship.invoicesApprovalMethod;
}
export function updatedAllowManualPayments(relationship) {
  return relationship.pendingAmendments.find(a => a.isAllowManualPayments());
}
export function updatedAllowManualPaymentsValue(relationship) {
  var _updatedAllowManualPa;
  return (_updatedAllowManualPa = updatedAllowManualPayments(relationship)) === null || _updatedAllowManualPa === void 0 ? void 0 : _updatedAllowManualPa.value();
}
export function updatedAllowManualPaymentsAutoApprovalDate(relationship) {
  const updated = updatedAllowManualPayments(relationship);
  return updated ? updated.approvalMethod().autoApprovalDate() : null;
}
export function actualAllowManualPayments(relationship) {
  const updated = updatedAllowManualPayments(relationship);
  return updated ? updated.value() : relationship.allowManualPayments;
}
export function hasAutoAmendments(relationship) {
  return relationship.services.some(serviceModel.hasAutoAmendments) || relationship.pendingAmendments.some(a => a.approvalMethod().isAutoApproval());
}
export function hasAmendments(relationship) {
  return numAmendments(relationship) !== 0;
}
export function numAmendments(relationship) {
  if (!isApproved(relationship)) return 0;
  return relationship.pendingAmendments.length + relationship.services.filter(s => serviceModel.isAmended(s.status) || serviceModel.isAwaitingApproval(s.status)).length;
}
export function numPendingInvoices(relationship) {
  return relationship.invoiceStatus.numRequiresPaymentApproval + relationship.invoiceStatus.numPaymentActionRequired;
}
export function numInvoicesRequiringApproval(relationship) {
  return relationship.invoiceStatus.numRequiresPaymentApproval;
}
export function numPendingCharges(relationship) {
  return relationship.adHocChargeStatus.numAwaitingApproval;
}
export function isCanceled(_ref) {
  let {
    status
  } = _ref;
  return status === RelationshipStatus.Canceled;
}
export function sellerFullName(relationship) {
  const {
    firstName,
    lastName
  } = relationship.seller;
  return fullName(firstName, lastName);
}
export function buyerFullName(relationship) {
  const {
    firstName,
    lastName
  } = relationship.buyer;
  return fullName(firstName, lastName);
}
export function reviewerFullName(reviewer) {
  return fullName(reviewer.firstName, reviewer.lastName);
}
export function sellerEmail(relationship) {
  return relationship.seller.email;
}
export function sellerID(relationship) {
  return relationship.seller.id;
}
export function buyerEmail(relationship) {
  return relationship.buyer.email;
}
export function buyerFirstName(relationship) {
  return relationship.buyer.firstName;
}
export function isBuyer(relationship, userId) {
  return relationship.buyer.id === userId;
}
export function isApproved(_ref2) {
  let {
    status
  } = _ref2;
  return status === RelationshipStatus.Approved;
}
export function isTerminated(_ref3) {
  let {
    status
  } = _ref3;
  return status === RelationshipStatus.Terminated;
}
export function isPending(_ref4) {
  let {
    status
  } = _ref4;
  return status === RelationshipStatus.Pending;
}
export function actualServices(relationship, selectedBundleID) {
  var _relationship$service;
  return relationship.serviceBundles ? ((_relationship$service = relationship.serviceBundles.bundles.find(b => b.id === selectedBundleID)) === null || _relationship$service === void 0 ? void 0 : _relationship$service.services) || [] : relationship.services;
}
export const opposingPartyName = (rel, isVendor) => isVendor ? customerName(rel) : vendorName(rel);
export function paymentMethod(relationship) {
  return relationship.paymentMethod;
}
export function paymentMethodType(relationship) {
  return relationship.paymentMethod ? relationship.paymentMethod.type : 'unknown';
}
export function customerName(relationship) {
  return relationship.suggestedCustomerName || relationship.customer.name;
}
export function selectedFundingSourceId(relationship) {
  var _relationship$custome;
  return ((_relationship$custome = relationship.customerSelections) === null || _relationship$custome === void 0 ? void 0 : _relationship$custome.fundingSourceId) || null;
}
export function vendorName(relationship) {
  return relationship.vendor.name;
}
export function vendorID(relationship) {
  return relationship.vendor.id;
}
export function vendorLogoUrl(relationship) {
  return relationship.vendor.logoUrl;
}
export function formatEffectiveDate(relationship) {
  const {
    effectiveDate
  } = relationship;
  return effectiveDate ? timeModel.formatDateLong(effectiveDate) : 'On acceptance';
}
export function hasIntroVideos(relationship) {
  var _relationship$introVi;
  return ((_relationship$introVi = relationship.introVideo) === null || _relationship$introVi === void 0 ? void 0 : _relationship$introVi.type) === IntroVideoType.VideoLink;
}
export function isSignatory(relationship, userId) {
  var _relationship$signato;
  return !!((_relationship$signato = relationship.signatories) !== null && _relationship$signato !== void 0 && _relationship$signato.some(s => s.id === userId));
}
export function hasAdditionalRecipients(relationship) {
  return !!(relationship.reviewers.length > 0 || relationship.signatories && relationship.signatories.length > 0);
}
export function hasSignatorySigned(relationship, signatoryId) {
  var _relationship$signato2;
  const signatory = (_relationship$signato2 = relationship.signatories) === null || _relationship$signato2 === void 0 ? void 0 : _relationship$signato2.find(s => s.id === signatoryId);
  return !!(signatory !== null && signatory !== void 0 && signatory.approvalRecord);
}
export function selectedBundleId(relationship) {
  var _relationship$custome2;
  return ((_relationship$custome2 = relationship.customerSelections) === null || _relationship$custome2 === void 0 ? void 0 : _relationship$custome2.bundleId) || null;
}
export function selectedOptionalServices(relationship) {
  var _relationship$custome3;
  return ((_relationship$custome3 = relationship.customerSelections) === null || _relationship$custome3 === void 0 ? void 0 : _relationship$custome3.optionalServices) || [];
}
export function includedByDefaultOptionalServices(relationship, selectedBundleID) {
  return actualServices(relationship, selectedBundleID).filter(s => serviceModel.isOptional(s.participation) && s.participation.optional.includedByDefault).map(s => s.id);
}
export function isReviewer(relationship, userId) {
  return relationship.reviewers.some(r => r.id === userId);
}
export function hasSignatories(relationship) {
  return relationship.signatories ? relationship.signatories.length > 0 : false;
}
export function status(relationship) {
  return relationship.status;
}
export function paymentTermsText(relationship) {
  const netTerms = actualNetTerms(relationship);
  const allowManualPayments = actualAllowManualPayments(relationship);
  const netTermsText = netTerms > 0 ? "".concat(pluralize('day', netTerms, true), " after the invoice date") : 'upon receipt of invoice';
  const manualPaymentsText = allowManualPayments ? 'requires client approval before processing' : 'is processed automatically';
  return "Payment is due ".concat(netTermsText, " and ").concat(manualPaymentsText, ".");
}
export function displayedTitle(relationship, isVendor) {
  return relationshipDisplayedTitle(relationship.title, opposingPartyName(relationship, isVendor));
}