// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kBHM6{display:flex;align-items:center;justify-content:center;flex-direction:column;gap:16px;height:100%;padding:24px;text-align:center}.P4LGA{max-width:1020px;text-align:center}.mcS4Z{display:flex;justify-content:center;gap:18px;margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noAccessPageContainer": `kBHM6`,
	"description": `P4LGA`,
	"buttonContainer": `mcS4Z`
};
export default ___CSS_LOADER_EXPORT___;
