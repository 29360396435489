import { compact } from 'lodash';
import { RevenueReportAggregateEnum, RevenueReportSortColumnId } from 'anchor-scout/types';
import { toDatestampArray } from "../../../models/time/translator";
import { formatDateLong } from "../../../models/time/selectors";
import { DateRange, SearchFilterType } from "../components/types";
import { Breakdown, FilterType } from "./types";
export const defaultAggregate = RevenueReportAggregateEnum.Month;
export const makeCsv = (headerRow, entries) => [headerRow].concat(entries.map(entry => {
  return compact([entry.formattedDay, entry.customer, entry.service, entry.rawRevenue.toString()]);
}));
export const makeFiltersConfig = (serviceNames, customerNames) => ({
  [FilterType.CustomerName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: customerNames,
    removable: true,
    withSearchbox: true
  },
  [FilterType.ServiceName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: serviceNames,
    removable: true,
    withSearchbox: true
  },
  [FilterType.DateRange]: {
    type: SearchFilterType.DateRangeWithPresets,
    removable: false
  },
  [FilterType.Aggregate]: {
    type: SearchFilterType.RadioFilter,
    title: 'Time Interval',
    options: aggregateOptions.map(_ref => {
      let {
        value,
        text
      } = _ref;
      return {
        value,
        label: text
      };
    }),
    defaultOption: defaultAggregate,
    removable: false
  },
  [FilterType.Breakdowns]: {
    type: SearchFilterType.MultiSelectFilter,
    title: 'Breakdown by',
    options: breakdownOptions.map(_ref2 => {
      let {
        value,
        text
      } = _ref2;
      return {
        value,
        label: text
      };
    }),
    removable: false,
    selectAllByDefault: false
  }
});
const dateToStr = date => formatDateLong(toDatestampArray(new Date(date)));
export const dataRangeNames = {
  [DateRange.Last12Months]: () => 'Last 12 months',
  [DateRange.CurrentMonth]: () => 'This month',
  [DateRange.CurrentQuarter]: () => 'This quarter',
  [DateRange.CurrentYear]: () => 'This year',
  [DateRange.AllTime]: () => 'All time',
  [DateRange.Custom]: (start, end) => "Custom range (".concat(dateToStr(start), " - ").concat(dateToStr(end), ")")
};
export const dateRangeOptions = [{
  value: DateRange.Last12Months,
  text: 'Last 12 months'
}, {
  value: DateRange.CurrentMonth,
  text: 'This month'
}, {
  value: DateRange.CurrentQuarter,
  text: 'This quarter'
}, {
  value: DateRange.CurrentYear,
  text: 'This year'
}, {
  value: DateRange.AllTime,
  text: 'All time'
}, {
  value: DateRange.Custom,
  text: 'Custom'
}];
export const aggregateOptions = [{
  value: RevenueReportAggregateEnum.Day,
  text: 'Day'
}, {
  value: RevenueReportAggregateEnum.Week,
  text: 'Week'
}, {
  value: RevenueReportAggregateEnum.Month,
  text: 'Month'
}, {
  value: RevenueReportAggregateEnum.Quarter,
  text: 'Quarter'
}, {
  value: RevenueReportAggregateEnum.Year,
  text: 'Year'
}, {
  value: RevenueReportAggregateEnum.AllTime,
  text: 'All time'
}];
export const aggregateNames = {
  [RevenueReportAggregateEnum.Day]: 'Day',
  [RevenueReportAggregateEnum.Week]: 'Week',
  [RevenueReportAggregateEnum.Month]: 'Month',
  [RevenueReportAggregateEnum.Quarter]: 'Quarter',
  [RevenueReportAggregateEnum.Year]: 'Year',
  [RevenueReportAggregateEnum.AllTime]: 'All time'
};
export const breakdownNames = {
  [Breakdown.Service]: 'Service name',
  [Breakdown.Customer]: 'Company name'
};
export const breakdownOptions = [{
  value: Breakdown.Service,
  text: breakdownNames[Breakdown.Service]
}, {
  value: Breakdown.Customer,
  text: breakdownNames[Breakdown.Customer]
}];
const makeServiceNameHeaderItem = () => ({
  sortId: RevenueReportSortColumnId.ServiceName,
  value: 'Service name'
});
const makeCompanyNameHeaderItem = () => ({
  sortId: RevenueReportSortColumnId.CompanyName,
  value: 'Company name'
});
const makeAggregateHeaderItem = aggregate => ({
  sortId: RevenueReportSortColumnId.Aggregate,
  value: aggregate
});
const makeRevenueHeaderItem = () => ({
  sortId: RevenueReportSortColumnId.Revenue,
  value: 'Revenue'
});
export const revenueReportHeaderColumnDefs = {
  ['']: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && makeAggregateHeaderItem(aggregateNames[aggregate]), makeRevenueHeaderItem()]),
  [Breakdown.Service]: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && makeAggregateHeaderItem(aggregateNames[aggregate]), makeServiceNameHeaderItem(), makeRevenueHeaderItem()]),
  [Breakdown.Customer]: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && makeAggregateHeaderItem(aggregateNames[aggregate]), makeCompanyNameHeaderItem(), makeRevenueHeaderItem()]),
  [[Breakdown.Customer, Breakdown.Service].join(',')]: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && makeAggregateHeaderItem(aggregateNames[aggregate]), makeCompanyNameHeaderItem(), makeServiceNameHeaderItem(), makeRevenueHeaderItem()])
};
export const revenueReportColumnDefs = {
  ['']: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && {
    width: 476,
    sortable: true
  }, {
    width: 476,
    sortable: true,
    hAlign: 'end'
  }]),
  [Breakdown.Service]: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && {
    width: 317,
    sortable: true
  }, {
    width: 317,
    sortable: true
  }, {
    width: 318,
    sortable: true,
    hAlign: 'end'
  }]),
  [Breakdown.Customer]: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && {
    width: 317,
    sortable: true
  }, {
    width: 317,
    sortable: true
  }, {
    width: 318,
    sortable: true,
    hAlign: 'end'
  }]),
  [[Breakdown.Customer, Breakdown.Service].join(',')]: aggregate => compact([aggregate !== RevenueReportAggregateEnum.AllTime && {
    width: 238,
    sortable: true
  }, {
    width: 238,
    sortable: true
  }, {
    width: 238,
    sortable: true
  }, {
    width: 238,
    sortable: true,
    hAlign: 'end'
  }])
};
export const filterLabels = {
  [FilterType.CustomerName]: 'Company name',
  [FilterType.ServiceName]: 'Service name',
  [FilterType.DateRange]: 'Date range',
  [FilterType.Aggregate]: aggregateNames[defaultAggregate],
  [FilterType.Breakdowns]: 'Breakdowns'
};
export const getDefaultSort = sortIds => ({
  colIndex: sortIds.indexOf(RevenueReportSortColumnId.Aggregate),
  sort: 'desc'
});
export const getHeaderItems = (breakdowns, aggregate) => revenueReportHeaderColumnDefs[breakdowns.sort().join(',')](aggregate);