// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WxEn4{height:80px}.AkBog{display:flex;flex-direction:column;gap:32px;margin-bottom:-16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreementName": `WxEn4`,
	"effectiveDate": `AkBog`
};
export default ___CSS_LOADER_EXPORT___;
