import { addMonths, startOfDay } from 'date-fns';
import { SearchFilterType } from "../components/types";
import { FilterType, FutureInvoicesReportSortColumnId, RequiresApproval } from "./types";
export const headerItems = [{
  value: 'Company name',
  sortId: FutureInvoicesReportSortColumnId.CompanyName
}, {
  value: 'Issue date',
  sortId: FutureInvoicesReportSortColumnId.IssueDate
}, {
  value: 'Due date',
  sortId: FutureInvoicesReportSortColumnId.DueDate
}, {
  value: 'Requires approval',
  sortId: FutureInvoicesReportSortColumnId.RequiresApproval
}, {
  value: 'Total',
  sortId: FutureInvoicesReportSortColumnId.Total
}, {
  value: ''
} // View Invoice button
];
export const futureInvoicesReportColumnDefs = [{
  width: [300, 330],
  sortable: true
}, {
  width: [120, 150],
  sortable: true
}, {
  width: [120, 150],
  sortable: true
}, {
  width: [130, 160],
  sortable: true
}, {
  width: [160, 300],
  sortable: true
}, {
  width: [140, 170],
  sortable: false,
  hAlign: 'end'
}];
export const makeFilterConfig = (filtersOptions, now) => {
  const today = startOfDay(now);
  const dateLimits = {
    min: today,
    max: addMonths(today, 12),
    minDateMessage: 'Date cannot be earlier than today',
    maxDateMessage: 'Date cannot be more than 12 months from today'
  };
  const initialDate = {
    from: now,
    to: addMonths(now, 1)
  };
  return {
    [FilterType.CompanyName]: {
      type: SearchFilterType.MultiSelectFilter,
      options: filtersOptions[FilterType.CompanyName] || [],
      withSearchbox: true,
      removable: true
    },
    [FilterType.AgreementName]: {
      type: SearchFilterType.MultiSelectFilter,
      options: filtersOptions[FilterType.AgreementName] || [],
      withSearchbox: true,
      removable: true
    },
    [FilterType.IssueDate]: {
      type: SearchFilterType.DateRangeFilter,
      dateLimits,
      initialDate,
      removable: true
    },
    [FilterType.DueDate]: {
      type: SearchFilterType.DateRangeFilter,
      dateLimits,
      initialDate,
      removable: true
    },
    [FilterType.RequiresApproval]: {
      type: SearchFilterType.MultiSelectFilter,
      options: Object.values(RequiresApproval),
      removable: true
    }
  };
};
export const makeCsv = futureInvoices => {
  const headersToExport = ['Company name', 'Agreement name', 'Issue date', 'Due date', 'Requires approval', 'Total'];
  return [headersToExport].concat(futureInvoices.map(futureInvoice => [futureInvoice.companyName(), futureInvoice.agreementName(), futureInvoice.issueDate(), futureInvoice.dueDate(), futureInvoice.requiresApproval() ? RequiresApproval.Yes : RequiresApproval.No, futureInvoice.total()]));
};
export const getDefaultSort = sortIds => ({
  colIndex: sortIds.indexOf(FutureInvoicesReportSortColumnId.IssueDate),
  sort: 'asc'
});