// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zSuGp{display:flex;align-items:center}.HuXjg{min-width:80px}.DwgDx{max-width:520px;max-height:100px;overflow:hidden;display:-webkit-box;line-clamp:4;-webkit-line-clamp:4;-webkit-box-orient:vertical;text-overflow:ellipsis}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeActivityRow": `zSuGp`,
	"timeActivityLabel": `HuXjg`,
	"notes": `DwgDx`
};
export default ___CSS_LOADER_EXPORT___;
